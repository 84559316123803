<template>
  <div>

    <div :class="`row align-items-center ${isToday ? 'justify-content-end' : 'justify-content-between'} ${!hasPatient ? (!hasDoctor ? 'mt-n3' : '') : 'mt-2'}`">
      <div
        v-if="!isToday"
        :class="!hasDoctor
          ? !hasPatient
            ? 'col-md-6 col-12'
            : 'col-md-9 col-12'
          : 'col-md-9 col-12'
          ">
        <button
          v-if="!isUpcoming"
          class="btn mx-1 btn-timeseries"
          v-bind:class="{
            'btn-info': btnActive.days,
            'btn-outline-info': btnActive.days == false,
          }"
          @click="btnActiveOnClick('days')">
          Hari Ini
        </button>
        <button
          class="btn mx-1 btn-timeseries"
          @click="btnActiveOnClick('month')"
          v-bind:class="{
            'btn-info': btnActive.month,
            'btn-outline-info': btnActive.month == false,
          }">
          Bulan Ini
        </button>
        <button
          class="btn mx-1 btn-timeseries"
          @click="btnActiveOnClick('lastMonth')"
          v-bind:class="{
            'btn-info': btnActive.lastMonth,
            'btn-outline-info': btnActive.lastMonth == false,
          }">
          Bulan Kemarin
        </button>
        <button
          class="btn mx-1 btn-timeseries"
          @click="btnActiveOnClick('year')"
          v-bind:class="{
            'btn-info': btnActive.year,
            'btn-outline-info': btnActive.year == false,
          }">
          Tahun Ini
        </button>
        <button
          class="btn mx-1 btn-timeseries"
          v-bind:class="{
            'btn-info': btnActive.period,
            'btn-outline-info': btnActive.period == false,
          }"
          @click="btnPeriodeOnClick">
          Periode
        </button>
      </div>
      <div
        class="col-md-3 col-6 mt-md-0 mt-n4"
        v-if="!hasDoctor && !isMyAppointment && currentUser.user_type_id != 1">
        <treeselect
          v-model="filter.doctor_id"
          :multiple="false"
          :options="doctors"
          placeholder="Filter Berdasar Dokter"
          @select="filterByDoctor" />
      </div>
      <div class="col-md-3 col-6 mt-md-0 mt-n4" v-if="!hasPatient">
        <b-input-group>
          <template #prepend>
            <b-input-group-text squared>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            type="text"
            v-model="filter.patient_name"
            placeholder="Cari Nama Pasien"
            @keyup="filterByPatient" />
        </b-input-group>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-md-2">
        <span
          style="width: 150px color: #245590 !important"
          class="font-weight-bold">{{ totalRows }} Baris Data</span>
      </div>
      <div class="col-12 col-md-6 col-xl-6" v-if="onShowPeriod">
        <span class="d-none d-sm-block">
          <b-input-group>
            <b-form-input
              v-model="filter.start_date"
              type="text"
              placeholder="Tanggal Awal"
              autocomplete="off"
              readonly></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                v-model="filter.start_date"
                button-only
                right
                reset-button
                locale="id"
                aria-controls="example-input"></b-form-datepicker>
            </b-input-group-append>
            <b-button squared variant="light">s/d</b-button>
            <b-form-input
              v-model="filter.end_date"
              type="text"
              placeholder="Tanggal Akhir"
              autocomplete="off"
              readonly></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                v-model="filter.end_date"
                button-only
                right
                reset-button
                locale="id"
                aria-controls="example-input"></b-form-datepicker>
            </b-input-group-append>
            <template #append>
              <b-button squared @click="filterByPeriode" variant="success">Cari</b-button>
              <b-button squared @click="resetFilterPeriode" variant="danger">Reset</b-button>
            </template>
          </b-input-group>
        </span>
        <span class="d-block d-sm-none">
          <div class="row">
            <div class="col-12 pb-1">
              <b-input-group>
                <b-form-input
                  v-model="filter.start_date"
                  type="text"
                  placeholder="Tanggal Awal"
                  autocomplete="off"
                  readonly></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="filter.start_date"
                    button-only
                    right
                    reset-button
                    locale="id"
                    aria-controls="example-input"></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </div>
            <div class="col-12 pt-0">
              <b-input-group>
                <b-button squared variant="light">s/d</b-button>
                <b-form-input
                  v-model="filter.end_date"
                  type="text"
                  placeholder="Tanggal Akhir"
                  autocomplete="off"
                  readonly></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="filter.end_date"
                    button-only
                    right
                    reset-button
                    locale="id"
                    aria-controls="example-input"></b-form-datepicker>
                </b-input-group-append>
                <template #prepend>
                  <b-button squared @click="filterByPeriode" variant="success">Cari</b-button>
                  <b-button squared @click="resetFilterPeriode" variant="danger">Reset</b-button>
                </template>
              </b-input-group>
            </div>
          </div>
        </span>
      </div>
    </div>

    <!-- Nav -->
    <template v-if="tabFiled == 0">
      <b-tabs class="mt-4 appointment-status-tabs" v-model="tabPosition">
        <b-tab title="Semua" active @click="resetAppointmentStatus"></b-tab>
        <div v-for="status in appointmentStatuses" :key="status.id">
          <b-tab
            :title="status.name"
            @click="navStatusOnClick(status.id)"></b-tab>
        </div>
      </b-tabs>
    </template>
    <template v-if="tabFiled == 1">
      <b-tabs class="mt-4 appointment-status-tabs" v-model="tabPosition">
        <b-tab title="Semua" @click="resetAppointmentStatus"></b-tab>
        <b-tab title="Diajukan" active @click="navStatusOnClick(1)"></b-tab>
        <div v-for="status in appointmentStatuses" :key="status.id">
          <b-tab
            :title="status.name"
            v-if="status.id != 1"
            @click="navStatusOnClick(status.id)"></b-tab>
        </div>
      </b-tabs>
    </template>

    <div class="d-flex justify-content-start align-items-center mt-5">
      <button
        class="btn btn-primary mr-1"
        @click="btnExportOnClick">
        Ekspor <i class="fas fa-print px-0 ml-1"></i>
      </button>
    </div>

    <!-- Table -->
    <b-table
      striped
      hover
      responsive
      class="mt-3"
      :items="items"
      :fields="fields">
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{
            width:
              hasDoctor || hasPatient
                ? field.key === 'actions'
                  ? '15%'
                  : ''
                : field.key === 'actions'
                  ? '25%'
                  : '',
          }" />
      </template>
      <template #cell(doctor_name_action_type_name)="data">
        <span class="d-block">{{ data.item.doctor_name }}</span>
        <span class="d-block">{{ data.item.action_type_name }}</span>
      </template>
      <template #cell(display_date)="data">
        {{
          new Date(data.item.date)
            .toLocaleString("id-ID", {
              weekday: "long",
              day: "numeric",
              month: "2-digit",
              year: "numeric",
            })
            .replaceAll("/", "-")
        }}
      </template>
      <template #cell(appointment_status_name)="data">
        <b-badge
          pill
          @click="btnStatusOnclick(data.item)"
          v-b-modal.modal-status
          :class="`${data.item.appointment_status_color} text-white`"
          style="font-size: 0.9rem">{{ data.item.appointment_status_name }}</b-badge>
      </template>
      <template #cell(actions)="data">
        <div class="d-flex">
          <b-button
            size="sm"
            class="mr-1"
            variant="primary"
            v-b-tooltip.hover
            title="Cetak Stiker Obat"
            @click="btnPrintStickerOnClick(data.item)"><i class="fas fa-print px-0"></i></b-button>
          <b-button
            size="sm"
            class="mr-1 btn-info"
            v-b-tooltip.hover
            title="Detail"
            @click="
              $router.push({ path: '/appointment/detail/' + data.item.id })
              "><i class="fas fa-eye px-0"></i></b-button>
          <template v-if="!hasPatient">
            <template v-if="!isMyAppointment">
              <b-button
                size="sm"
                class="mr-1 btn-success"
                v-b-tooltip.hover
                title="Edit"
                v-if="manipulateBtn == true"
                @click="
                  $router.push({ path: '/appointment/edit/' + data.item.id })
                  "><i class="fas fa-edit px-0"></i></b-button>
              <b-button
                size="sm"
                class="btn-danger mr-1"
                v-b-tooltip.hover
                title="Hapus"
                v-if="manipulateBtn == true"
                @click="btnDeleteOnClick(data.item.id)"><i class="fas fa-trash px-0"></i></b-button>
            </template>
          </template>
          <template v-if="data.item.appointment_status_id == 4">
            <b-button
              v-if="caseManagerShortcut"
              size="sm"
              class="mr-1 btn-success"
              v-b-tooltip.hover
              title="Tambah Rekam Medis"
              @click="
                $router.push(
                  `/patient/cases?doctor_id=${data.item.doctor_id}&patient_id=${data.item.patient_id}&patient_name=${data.item.patient_name}&appointment_id=${data.item.id}`
                )
                "><i class="fas fa-user-md px-0"></i></b-button>
            <b-button
              v-if="paymentShortcut"
              size="sm"
              class="mr-1 btn-primary"
              variant="primary"
              v-b-tooltip.hover
              title="Tambah Invoice"
              @click="
                $router.push(
                  `/payment/add?doctor_id=${data.item.doctor_id}&patient_id=${data.item.patient_id}&patient_name=${data.item.patient_name}&appointment_id=${data.item.id}`
                )
                "><i class="fas fa-money-bill-wave px-0"></i></b-button>
            <b-button
              v-if="prescriptionShortcut"
              size="sm"
              class="mr-1 btn-info"
              v-b-tooltip.hover
              title="Tambah Resep"
              @click="
                $router.push(
                  `/prescriptions/add?doctor_id=${data.item.doctor_id}&doctor_name=${data.item.doctor_name}&patient_id=${data.item.patient_id}&patient_name=${data.item.patient_name}&appointment_id=${data.item.id}`
                )
                "><i class="fas fa-receipt px-0"></i></b-button>
            <b-button
              v-if="medicalActionShortcut"
              size="sm"
              class="btn-success"
              v-b-tooltip.hover
              title="Tambah Tindakan Medis"
              @click="
                $router.push(
                  `/medical-actions/add?doctor_id=${data.item.doctor_id}&doctor_name=${data.item.doctor_name}&patient_id=${data.item.patient_id}&patient_name=${data.item.patient_name}&appointment_id=${data.item.id}`
                )
                "><i class="fas fa-diagnoses px-0"></i></b-button>
          </template>
        </div>
      </template>
    </b-table>
    <b-pagination
      v-if="items.length != 0"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-click="pageOnClick"
      class="mt-4"></b-pagination>

    <!-- modal status -->
    <b-modal
      id="modal-status"
      title="Ubah Status Perjanjian"
      size="lg"
      @ok="modalStatusOnSubmit"
      @hidden="modalStatusOnHide">
      <b-form-group
        label="Pilih Status Perjanjian"
        v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          id="radio-group-status"
          v-model="item.appointment_status_id"
          :options="radioAppointmentStatus"
          :aria-describedby="ariaDescribedby"
          name="appointment-status-id"></b-form-radio-group>
      </b-form-group>
    </b-modal>

    <!-- modal export -->
    <b-modal
      id="modal-excel"
      size="xl"
      title="Expor Daftar Perjanjian"
      style="max-width: 30px overflow: scroll"
      hide-footer>
      <div class="d-flex justify-content-start align-items-center mt-5">
        <button
          class="btn btn-warning mr-1"
          v-b-tooltip.hover
          title="Download Excel"
          @click="btnExcelOnClick">
          <i class="fas px-0 fa-file-excel"></i>
        </button>
      </div>
      <b-table
        striped
        hover
        responsive
        class="mt-3"
        :items="itemPopUp"
        :fields="fieldPopUp">
        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{
              width:
                hasDoctor || hasPatient
                  ? field.key === 'actions'
                    ? '15%'
                    : ''
                  : field.key === 'actions'
                    ? '25%'
                    : '',
            }" />
        </template>
        <template #cell(doctor_name_action_type_name)="data">
          <span class="d-block">{{ data.item.doctor_name }}</span>
          <span class="d-block">{{ data.item.action_type_name }}</span>
        </template>
        <template #cell(display_date)="data">
          {{
            new Date(data.item.date).toLocaleString("id-ID", {
              weekday: "long",
              day: "numeric",
              month: "numeric",
              year: "numeric",
            })
          }}
        </template>
        <template #cell(appointment_status_name)="data">
          <div class="text-center">
            {{ data.item.appointment_status_name }}
          </div>
        </template>
      </b-table>
    </b-modal>

    <!-- print layout -->
    <ModalFormSticker :data="print" :count="printCount" />

  </div>
</template>

<script>

import XLSX from "xlsx"
import ModalFormSticker from "@/component/appointments/ModalFormStickerPrint.vue"
import Treeselect from "@riophae/vue-treeselect"
import "@riophae/vue-treeselect/dist/vue-treeselect.css"
import { debouncer } from "@/core/modules/Helper.js"
import module from "@/core/modules/CrudModule.js"
import AppointmentModule from "@/core/modules/AppointmentModule"
import DoctorModule from "@/core/modules/DoctorModule"
import validation from "@/core/modules/ValidationModule.js"
import { getUser } from "@/core/services/jwt.service.js"
import moment from "moment"

export default {

  props: {
    isToday: Boolean,
    isUpcoming: Boolean,
    hasDoctor: Boolean,
    hasPatient: Boolean,
    isMyAppointment: Boolean,
    fields: Array,
    items: Array,
    itemExcel: Array,
    itemPopUp: Array,
    perPage: Number,
    currentPage: Number,
    totalRows: Number,
    tabFiled: Number,
  },

  components: {
    Treeselect,
    ModalFormSticker,
    XLSX,
  },

  data() {
    return {
      filter: {
        appointment_status_id: "",
        doctor_id: "",
        patient_name: "",
        start_date: "",
        end_date: "",
      },
      onShowPeriod: false,
      btnActive: {
        days: true,
        month: false,
        lastMonth: false,
        year: false,
        period: false,
      },
      fieldPopUp: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "display_date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "time",
          label: "Waktu",
          sortable: true,
        },
        {
          key: "patient_name",
          label: "Pasien",
          sortable: true,
        },
        {
          key: "doctor_name_action_type_name",
          label: "Dokter & Jenis Tindakan",
          sortable: true,
        },
        {
          key: "appointment_status_name",
          label: "Status",
          sortable: true,
        },
      ],
      appointmentStatuses: [],
      doctors: [{ label: "Filter Berdasar Dokter", id: "", isDisabled: true }],
      radioAppointmentStatus: [],
      item: {},
      tabPosition: 2,
      tabActive: false,
      tabNumber: 5,
      print: {
        patient_number: "",
        patient_name: "",
        patient_gender: "",
        patient_birth_date: "",
        appointment_date: "",
      },
      printCount: 2,
      manipulateBtn: false,
      statusAppointment: false,
      caseManagerShortcut: false,
      paymentShortcut: false,
      prescriptionShortcut: false,
      medicalActionShortcut: false,
      currentUser: getUser(),
      setting: {},
    }
  },

  methods: {

    btnPrintStickerOnClick(item) {
      this.$bvModal.show("modal-form-sticker-print")
      this.print.patient_number = item.patient_number
      this.print.patient_name = item.display_patient_name
      this.print.patient_gender = item.patient_gender
      this.print.patient_birth_date = item.patient_birth_date
      this.print.appointment_date = item.date
    },

    async getSetting() {
      this.setting = await module.get(`settings/${1}`)
    },

    btnExportOnClick() {
      this.$bvModal.show('modal-excel')
      this.$emit('btnExportOnClick', this.filter)
    },

    btnExcelOnClick() {
      const data = XLSX.utils.json_to_sheet(this.itemExcel)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, data, "data")
      XLSX.writeFile(wb, `Daftar Perjanjian ${this.setting.clinic_name}.xlsx`)
    },

    async getAppointmentStatuses() {
      const data = await AppointmentModule.getSimpleAppointmentStatuses()
      this.appointmentStatuses = data
      data.forEach((value) => {
        this.radioAppointmentStatus.push({ text: value.name, value: value.id })
      })
    },

    async getDoctorsOption() {
      const data = await DoctorModule.getTreeSelect()
      this.doctors.push(...data)
    },

    pageOnClick(evt, page) {
      evt.preventDefault()
      this.$emit("pageOnClick", page)
    },

    resetFilterPeriode() {
      this.filter = validation.clearForm(this.filter)
      if (this.currentUser.user_type_id == 1) {
        this.filter.doctor_id = this.currentUser.user_id
      }
      this.btnActiveOnClick(this.lastPeriodType)
      this.$emit("filter", this.filter)
    },

    filterByPeriode() {
      this.$emit("filter", this.filter)
    },

    filterByPatient() {
      debouncer(() => {
        this.tabPosition = 0
        this.filter.start_date = ""
        this.filter.end_date = ""
        if (this.filter.patient_name == "") {
          for (const [key, value] of Object.entries(this.btnActive)) {
            if (value == true) {
              this.btnActiveOnClick(key)
            }
          }
        }
        this.$emit("filterByPatient", this.filter)
      }, 500)
    },

    resetAppointmentStatus() {
      this.filter.appointment_status_id = ""
      this.$emit("filter", this.filter)
    },

    async filterByDoctor(evt) {
      await this.filter.doctor_id
      this.$emit("filter", this.filter)
    },

    navStatusOnClick(appointmentStatusId) {
      this.filter.appointment_status_id = appointmentStatusId
      this.$emit("filter", this.filter)
    },

    btnStatusOnclick(item) {
      for (const [key, value] of Object.entries(item)) {
        this.item[key] = value
      }
    },

    async modalStatusOnSubmit(evt) {
      this.item["_method"] = "put"
      let response = await module.submit(
        this.item,
        `appointments/change-status/${this.item.id}`
      )
      if (response.state == "error") {
        Swal.fire("Gagal", "Terjadi Kesalahan", "error")
        return
      }
      this.$root.$emit("UpdateNewAppointment")
      this.$emit("appointmentOnStatusChange")
      Swal.fire(response.success.title, response.success.message, "success")
    },

    modalStatusOnHide() {
      this.item = {}
    },

    btnDeleteOnClick(id) {
      this.$emit("btnDeleteOnClick", id)
    },

    // Global emit
    globalEmit() {
      this.$root.$emit("filterAppointment", this.filter)
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      )
      let access_right = JSON.parse(access_right_user)
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "5002") this.manipulateBtn = true
        if (access_right[a] == "5003") this.statusAppointment = true
        if (access_right[a] == "3006") this.caseManagerShortcut = true
        if (access_right[a] == "6002") this.paymentShortcut = true
        if (access_right[a] == "8002") this.prescriptionShortcut = true
        if (access_right[a] == "1202") this.medicalActionShortcut = true
      }
    },

    btnPeriodeOnClick() {
      if (this.onShowPeriod == false) {
        this.onShowPeriod = true
        this.btnActiveOnClick("period")
      } else {
        this.onShowPeriod = false
        this.btnActiveOnClick("period")
      }
    },

    btnActiveOnClick(type) {
      if (type == "days") {
        // set time
        this.filter.start_date = moment()
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss")
        this.filter.end_date = moment()
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss")

        // get data
        // this.pagination()
        this.$emit("filter", this.filter)

        // set btn Active
        this.btnActive.days = true
        this.btnActive.month = false
        this.btnActive.lastMonth = false
        this.btnActive.year = false
        this.btnActive.period = false

        // hide period
        this.onShowPeriod = false
      } else if (type == "month") {
        // set time
        this.filter.start_date = moment()
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss")
        this.filter.end_date = moment()
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss")

        // get data
        // this.pagination()
        this.$emit("filter", this.filter)

        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = true
        this.btnActive.lastMonth = false
        this.btnActive.year = false
        this.btnActive.period = false

        // hide period
        this.onShowPeriod = false
      } else if (type == "lastMonth") {
        // set time
        this.filter.start_date = moment()
          .subtract(1, "months")
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss")
        this.filter.end_date = moment()
          .subtract(1, "months")
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss")

        // get data
        // this.pagination()
        this.$emit("filter", this.filter)

        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = false
        this.btnActive.lastMonth = true
        this.btnActive.year = false
        this.btnActive.period = false

        // hide period
        this.onShowPeriod = false
      } else if (type == "year") {
        // set time
        this.filter.start_date = moment()
          .startOf("year")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss")
        this.filter.end_date = moment()
          .endOf("year")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss")

        // get data
        // this.pagination()
        this.$emit("filter", this.filter)

        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = false
        this.btnActive.lastMonth = false
        this.btnActive.year = true
        this.btnActive.period = false

        // hide period
        this.onShowPeriod = false
      } else if (type == "period") {
        // set time
        this.filter.start_date = ""
        this.filter.end_date = ""

        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = false
        this.btnActive.lastMonth = false
        this.btnActive.year = false
        this.btnActive.period = true
      }
    }

  },

  watch: {
    "filter.doctor_id": function (newVal, oldVal) {
      if (typeof newVal === "undefined") {
        this.filter.doctor_id = ""
        this.filterByDoctor()
      }
    }
  },

  mounted() {
    this.$root.$on("appointment-submission", () => {
      this.tabActive = true
      this.filter.appointment_status_id = 5
      this.globalEmit()
    })
    this.setActiveMenu()
    this.getSetting()
    this.getAppointmentStatuses()
    if (this.currentUser.user_type_id != 1)
      this.getDoctorsOption()
    if (this.isUpcoming) {
      this.btnActive.days = false
      this.btnActive.month = true
    }
  }

}
</script>

<style scoped>
.appointment-status-tabs .nav-tabs {
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap
}

.appointment-status-tabs .nav-tabs .nav-link {
  white-space: nowrap
}
</style>
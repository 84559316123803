<template>
  <div>
    <!-- Calendar -->
    <v-sheet height="64">
      <v-toolbar flat>
        <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
          Today
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="prev">
          <v-icon small> mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="next">
          <v-icon small> mdi-chevron-right </v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
              <span>{{ typeToLabel[type] }}</span>
              <v-icon right> mdi-menu-down </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="type = 'day'">
              <v-list-item-title>Day</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'week'">
              <v-list-item-title>Week</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'month'">
              <v-list-item-title>Month</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = '4day'">
              <v-list-item-title>4 days</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
    </v-sheet>
    <v-sheet height="600">
      <v-calendar
        ref="calendar"
        v-model="value"
        locale="id"
        :weekdays="weekday"
        :type="type"
        :events="events"
        :event-overlap-threshold="30"
        :event-color="getEventColor"
        @click:event="showEvent"
        @click:more="viewDay"
        @click:date="viewDay"
        @change="getDoctorEvents"
      ></v-calendar>
      <v-menu
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-x
      >
        <v-card color="grey lighten-4" min-width="350px" flat>
          <v-toolbar :color="selectedEvent.color" dark>
            <v-toolbar-title v-html="`${selectedEvent.name}`"></v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text style="font-size: 13px">
            <p class="py-0">{{ selectedEvent.details }}</p>
            <!-- <p class="py-0">Keterangan : {{ selectedEvent.notes }}</p> -->
            <template v-if="selectedEvent.type == 'Perjanjian'">
              <p class="py-0">{{ selectedEvent.notes }}</p>
              <p class="py-0">{{ selectedEvent.status }}</p>
              <p class="py-0">{{ selectedEvent.action }}</p>
            </template>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="secondary" @click="selectedOpen = false">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-sheet>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";

export default {
  props: {
    isMyAppointment: Boolean,
    currentDoctor: Object,
  },

  data: () => ({
    // Calendar
    type: "month",
    typeToLabel: {
      month: "Per Bulan",
      week: "Per Minggu",
      day: "Per Hari",
      "4day": "Per 4 Hari",
    },
    weekday: [0, 1, 2, 3, 4, 5, 6],
    value: "",
    events: [],
    // colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    colors: ["bg-filed", "bt-conf-wait", "bt-conf", "bt-handled", "bg-danger"],
    // Event
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
  }),

  methods: {
    async getDoctorEvents() {
      const events = [];
      let doctor_id = this.isMyAppointment
        ? this.currentDoctor.id
        : this.$route.params.id;      
      const doctorEvents = await module.list("doctors/get-events/" + doctor_id);
      const eventCount = doctorEvents.length;
      for (let i = 0; i < eventCount; i++) {
        events.push({
          name: doctorEvents[i].name,
          type: doctorEvents[i].type,
          start: new Date(doctorEvents[i].start_time),
          end: new Date(doctorEvents[i].end_time),
          doctor_name: doctorEvents[i].doctor_name,
          notes: doctorEvents[i].notes,
          status_name: doctorEvents[i].appointment_status_name,
          action_type_name: doctorEvents[i].action_type_name,
          color: this.colors[this.random(0, this.colors.length - 1)],
          timed: true,
        });
      }
      this.events = events;
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;        
        if (event.type == "Perjanjian") {
          let dayName = new Date(event.start).toLocaleDateString("id-ID", {
            weekday: "long",
          });
          let start = new Date(event.start).toLocaleTimeString("id-ID", {
            hour: "2-digit",
            minute: "2-digit",
          });
          let end = new Date(event.end).toLocaleTimeString("id-ID", {
            hour: "2-digit",
            minute: "2-digit",
          });
          this.selectedEvent.details = `${event.name} Pada ${dayName} ${start} s/d ${end} dengan ${event.doctor_name}`;
          this.selectedEvent.notes = `Catatan: ${event.notes}`;
          this.selectedEvent.status = `Status: ${event.status_name}`;
          this.selectedEvent.action = `Tindakan: ${event.action_type_name}`;
        } else {
          let fullDate = new Date(event.start).toLocaleDateString("id-ID", {
            weekday: "long",
            day: "numeric",
            month: "numeric",
            year: "numeric",
          });
          this.selectedEvent.details = `${event.name} Pada ${fullDate}`;
        }
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 30);
      };
      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 30);
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },

    viewDay({ date }) {
      this.value = date;
      this.type = "day";
    },

    setToday() {
      this.value = "";
    },

    prev() {
      this.$refs.calendar.prev();
    },

    next() {
      this.$refs.calendar.next();
    },

    getEventColor(event) {
      return event.color;
    },

    random(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
};
</script>

<style lang="scss">
@import "~vuetify/dist/vuetify.css";
.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}

.bg-filed {
  background-color: #007bff !important;
}
.bg-conf-wait {
  background-color: #ffc107 !important;
  color: black !important;
}
.bg-conf {
  background-color: #28a745 !important;
}
.bg-handled {
  background-color: #17a2b8 !important;
}
</style>
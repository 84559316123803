import api from '@/core/services/api.service'
var dbconfigparams = window.localStorage.getItem("dbconfigparams")

const AppointmentModule = {

  async getTimeSlots(date, weekday, doctorId) {
    let arr = []
    let response = await api.get('time-slots/get-by-date', `?date=${date}&weekday=${weekday}&doctor=${doctorId}`)
    response.data.data.forEach(value => {
      arr.push({
        text: value.name,
        value: value.id,
        start_time: value.start_time,
        end_time: value.end_time
      })
    })
    arr.unshift({ text: 'Pilih Jadwal', value: '', disabled: true })
    arr.push({ text: 'Kustom', value: 'custom' })
    return arr
  },

  async getTimeSlotsSelect(date, weekday, doctorId) {
    let result = []
    const response = await api.get('time-slots/get-by-date/select-options', `?date=${date}&weekday=${weekday}&doctor=${doctorId}`)
    if (response.status == 200)
      result = [...[{ text: 'Kustom', value: 'custom' }], ...response.data.data]
    return result
  },

  async getUnusedRoomsTreeSelect(date, startTime, endTime, appointmentId) {
    let arr = []
    let queryParams = `?date=${date}&start_time=${startTime}&end_time=${endTime}`
    if (appointmentId != null)
      queryParams += `&appointment_id=${appointmentId}`
    const response = await api.get('rooms/get-unused-room/treeselect', queryParams)
    if (response.status == 200)
      arr = response.data.data
    return arr
  },

  async getRoomsTreeSelect() {
    let arr = []
    const response = await api.get('rooms/treeselect')
    if (response.status == 200)
      arr = response.data.data
    return arr
  },

  async getRooms() {
    let arr = []
    let response = await api.get('rooms')
    response.data.data.forEach((value) => {
      arr.push({ label: value.name, id: value.id })
    })
    arr.unshift({ label: 'Pilih Ruangan', id: '', isDisabled: true })
    return arr
  },

  async getAppointmentStatusesSelect() {
    let result = []
    const response = await api.get('appointment-statuses/select-options', `?${dbconfigparams}`)
    if (response.status == 200)
      result = response.data.data
    return result
  },

  async getAppointmentStatusesTreeSelect() {
    let result = []
    const response = await api.get('appointment-statuses/treeselect', `?${dbconfigparams}`)
    if (response.status == 200)
      result = response.data.data
    return result
  },

  async getAppointmentStatuses() {
    let obj = { treeselect: [], data: [] }
    let response = await api.get('appointment-statuses/colors', `?${dbconfigparams}`)
    if (response.status == 200) {
      response.data.data.forEach((value) => {
        obj.treeselect.push({ label: value.name, id: value.id })
        obj.data.push(value)
      })
      return obj
    }
    return null
  },

  async getSimpleAppointmentStatuses() {
    let arr = []
    const response = await api.get('appointment-statuses/colors', `?${dbconfigparams}`)
    if (response.status == 200)
      arr = response.data.data
    return arr
  }

}

export default AppointmentModule
import api from '@/core/services/api.service'

const DoctorModule = {

  async getTreeSelect(patientId = null) {
    let queryParams = '', result = []
    if (typeof patientId != 'undefined' && patientId)
      queryParams = `?patient_id=${patientId}`
    const response = await api.get(`doctors/treeselect${queryParams}`)
    if (response.status == 200)
      result = response.data.data
    return result
  }

}

export default DoctorModule
<template>
  <div>

    <b-modal
      id="modal-form-sticker-print"
      title="Cetak Stiker"
      size="md"
      ok-title="Oke"
      cancel-title="Batal"
      @ok="onSubmit"
      @hidden="onHidden"
    >
      <b-form-group
        id="input-group-print-count"
        label="Masukan jumlah stiker"
        label-for="input-print-count"
      >
        <b-form-select
          v-model="count"
          :options="counts"
          placeholder="Tentukan jumlah stiker"
        ></b-form-select>
      </b-form-group>
    </b-modal>

    <div
      id="print"
      class="d-none"
    >

      <!-- print preview -->
      <div id="printPreview">
        <div class="row justify-content-center align-items-center h-100 w-100">
          <div
            class="col-lg-5 col-12 py-2 sticker-container"
            v-for="loop in parseInt(count)"
            :key="loop"
          >
            <div
              class="card border-secondary"
              style="border-radius: 0px"
            >
              <div class="card-body py-5 d-flex justify-content-center align-items-center">
                <table>
                  <tr>
                    <td
                      style="width: 120px"
                      class="py-2"
                    ><b>Pasien</b></td>
                    <td
                      style="width: 15px;"
                      class="text-center"
                    > : </td>
                    <td>{{ `${data.patient_name} (${mutated_gender})` }}</td>
                  </tr>
                  <tr>
                    <td class="py-2"><b>No.RM - Tgl lahir</b></td>
                    <td
                      style="width: 15px;"
                      class="text-center"
                    > : </td>
                    <td>{{ data.patient_number }} - {{ new Date(data.patient_birth_date).toLocaleString('id-ID', {day: 'numeric', month: 'long', year: 'numeric'}).replaceAll('/', '-') }}</td>
                  </tr>
                  <tr>
                    <td class="py-2"><b>Tanggal Kunjungan</b></td>
                    <td
                      style="width: 15px;"
                      class="text-center"
                    > : </td>
                    <td>{{ new Date(data.appointment_date).toLocaleString('id-ID', {day: 'numeric', weekday: 'long', month: '2-digit', year: 'numeric'}).replaceAll('/', '-') }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- print layout -->
      <div
        id="printLayout"
        class="d-none"
      >
        <div
          class="d-flex align-items-center justify-content-center"
          v-for="loop in parseInt(count / 2)"
          :key="loop"
        >
          <div
            class="card sticker-container"
            style="border-radius: 0px"
          >
            <div class="card-body d-flex justify-content-center align-items-center">
              <table>
                <tr>
                  <td
                    style="width: 75px"
                    class="py-2"
                  ><b>Pasien</b></td>
                  <td
                    style="width: 10px;"
                    class="text-center"
                  > : </td>
                  <td>{{ `${data.patient_name} (${mutated_gender})` }}</td>
                </tr>
                <tr>
                  <td class="py-2"><b>No.RM - Tgl lahir</b></td>
                  <td
                    style="width: 10px;"
                    class="text-center"
                  > : </td>
                  <td>{{ data.patient_number }} - {{ new Date(data.patient_birth_date).toLocaleString('id-ID', {day: 'numeric', month: 'long', year: 'numeric'}).replaceAll('/', '-') }}</td>
                </tr>
                <tr>
                  <td class="py-2"><b>Tanggal Kunjungan</b></td>
                  <td
                    style="width: 10px;"
                    class="text-center"
                  > : </td>
                  <td>{{ new Date(data.appointment_date).toLocaleString('id-ID', {day: 'numeric', weekday: 'long', month: '2-digit', year: 'numeric'}).replaceAll('/', '-') }}</td>
                </tr>
              </table>
            </div>
          </div>
          <div
            class="card sticker-container"
            style="border-radius: 0px"
          >
            <div class="card-body d-flex justify-content-center align-items-center">
              <table>
                <tr>
                  <td
                    style="width: 75px"
                    class="py-2"
                  ><b>Pasien</b></td>
                  <td
                    style="width: 10px;"
                    class="text-center"
                  > : </td>
                  <td>{{ `${data.patient_name} (${mutated_gender})` }}</td>
                </tr>
                <tr>
                  <td class="py-2"><b>No.RM - Tgl lahir</b></td>
                  <td
                    style="width: 10px;"
                    class="text-center"
                  > : </td>
                  <td>{{ data.patient_number }} - {{ new Date(data.patient_birth_date).toLocaleString('id-ID', {day: 'numeric', month: 'long', year: 'numeric'}).replaceAll('/', '-') }}</td>
                </tr>
                <tr>
                  <td class="py-2"><b>Tanggal Kunjungan</b></td>
                  <td
                    style="width: 10px;"
                    class="text-center"
                  > : </td>
                  <td>{{ new Date(data.appointment_date).toLocaleString('id-ID', {day: 'numeric', weekday: 'long', month: '2-digit', year: 'numeric'}).replaceAll('/', '-') }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {

  props: {
    data: Object,
    count: Number
  },

  data() {
    return {
      counts: [
        { value: 2, text: '2 stiker' },
        { value: 4, text: '4 stiker' },
        { value: 6, text: '6 stiker' },
        { value: 8, text: '8 stiker' },
        { value: 10, text: '10 stiker' },
      ]
    }
  },

  computed: {
    mutated_gender: {
      set(newValue) {
        this.data.patient_gender = newValue
      },
      get() {
        if (this.data.patient_gender == 'male') return 'L'
        else if (this.data.patient_gender == 'female') return 'P'
        else return ''
      }
    }
  },

  methods: {

    onSubmit(e) {
      e.preventDefault()
      if (isNaN(this.count) || this.count == '') {
        Swal.fire({
          title: 'Pemberitahuan',
          text: 'Jumlah cetak tidak valid',
          icon: 'warning',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true
        })
        return
      }
      if (this.count % 2 != 0) {
        Swal.fire({
          title: 'Pemberitahuan',
          text: 'Jumlah print harus bilangan kelipatan 2',
          icon: 'warning',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true
        })
        return
      }
      this.$htmlToPaper('print', {
        name: 'cetak stiker',
        specs: [
          'fullscreen=yes',
          'titlebar=yes',
          'scrollbars=yes'
        ],
        styles: [
          'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
          'https://unpkg.com/kidlat-css/css/kidlat.css',
          `/sticker.css`
        ]
      })
    },

    onHidden() {
      this.count = 2
    }

  }

}
</script>

<style>
</style>
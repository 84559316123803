<template>
  <v-app>

    <div class="row">

      <div class="col-md-9">
        <Card :title="'Kalender Dokter'">
          <template v-slot:body>
            <b-tabs class="mt-n3" content-class="mt-3">
              <b-tab title="Kalender" active>
                <Calendar />
              </b-tab>
              <b-tab title="Perjanjian">
                <Table
                  :items="items"
                  :itemExcel="itemExcel"
                  :itemPopUp="itemPopUp"
                  :fields="fields"
                  :hasDoctor="true"
                  :perPage="perPage"
                  :currentPage="currentPage"
                  :totalRows="totalRows"
                  :tabFiled="tabFiled"
                  @appointmentOnStatusChange="pagination"
                  @filter="generalFilter"
                  @filterByPatient="generalFilter"
                  @btnDeleteOnClick="btnDeleteOnClick"
                  @pageOnClick="pageOnClick"
                  @btnExportOnClick="btnExportOnClick" />
              </b-tab>
            </b-tabs>
          </template>
        </Card>
      </div>

      <div class="col-md-3">
        <Card title="Dokter">
          <template v-slot:body>
            <div class="d-flex align-items-center">
              <div class="symbol symbol-60 symbol-xxl-100 mr-3 align-self-start align-self-xxl-center">
                <div style='height: 80px; width: 80px; position: relative'>
                  <img
                    class="image"
                    :src="user.photo">
                </div>
                <i class="symbol-badge bg-success"></i>
              </div>
              <div>
                <a class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">{{ user.name }}</a>
                <div class="text-muted">{{ user.polyclinic_name }}</div>
                <div class="mt-2">
                  <button
                    class="btn btn-sm btn-info font-weight-bold mr-2 py-2 px-3 px-xxl-5 my-1"
                    @click="$router.push({ path: '/doctor/detail/' + user.id })">Info</button>
                </div>
              </div>
            </div>
            <div class="py-9">
              <div class="d-flex mb-2">
                <span class="font-weight-bold mr-2">Email:</span>
                <a class="text-muted text-hover-primary">{{ user.email }}</a>
              </div>
              <div class="d-flex mb-2">
                <span class="font-weight-bold mr-2">Phone:</span>
                <span class="text-muted">{{ user.phone }}</span>
              </div>
            </div>
          </template>
        </Card>
      </div>

    </div>

  </v-app>
</template>

<script>

import Card from '@/view/content/Card.vue'
import Table from '@/component/appointments/Table.vue'
import Calendar from '@/component/doctors/Calendar.vue'
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

const ApiRoute = 'appointments/get-by-doctor/'

export default {

  components: {
    Card,
    Table,
    Calendar
  },

  data() {
    return {
      user: {},
      filter: {
        appointment_status_id: '',
        start_date: '',
        end_date: '',
        patient_name: '',
        doctor_id: this.$route.params.id
      },
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "patient_name",
          label: "Pasien",
          sortable: true,
        },
        {
          key: "display_date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "time",
          label: "Waktu",
          sortable: true,
        },
        {
          key: "action_type_name",
          label: "Jenis Tindakan",
          sortable: true,
        },
        {
          key: "appointment_status_name",
          label: "Status",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      items: [],
      itemExcel: [],
      itemPopUp: [],
      tabFiled: 0,
    }
  },

  methods: {

    pageOnClick(page) {
      this.currentPage = page
      this.pagination()
    },

    async getUser() {
      this.user = await module.get(`doctors/${this.$route.params.id}`)
      if (this.user == null)
        this.$router.push('/doctors/list')
    },

    async pagination() {
      let filterParams = `&appointment_status_id=${this.filter.appointment_status_id}&doctor_id=${this.filter.doctor_id}&patient_name=${this.filter.patient_name}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`
      let response = await module.paginate(ApiRoute + this.$route.params.id, `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      let pagination = response.meta.pagination
      this.items = response.data
      this.totalRows = pagination.total
    },

    async getExport() {
      const excelRoute = 'appointment-excel'
      const popUpRoute = 'appointment-no-paginations'
      let filterParams = `&appointment_status_id=${this.filter.appointment_status_id}&patient_name=${this.filter.patient_name}&doctor_id=${this.filter.doctor_id}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`
      let responseExcel = await module.get(excelRoute, `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      let responsePopUp = await module.get(popUpRoute, `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      this.itemExcel = responseExcel
      this.itemPopUp = responsePopUp
    },

    setFilter(sent) {
      for (const key in this.filter) {
        this.filter[key] = sent[key]
      }
      if (
        this.filter.patient_name != '' ||
        this.filter.appointment_status_id != '' ||
        this.filter.doctor_id != '' ||
        this.filter.start_date != '' ||
        this.filter.end_date != ''
      )
        this.currentPage = 1
    },

    async generalFilter(sent) {
      this.setFilter(sent)
      await this.pagination()
    },

    async btnDeleteOnClick(id) {
      let result = await module.delete(`${ApiRoute}/${id}`)
      if (result) {
        this.$root.$emit('UpdateNewAppointment')
        this.pagination()
      }
    },

    async btnExportOnClick(filter) {
      this.setFilter(filter)
      await this.getExport()
    },

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dokter", route: "" },
      { title: "Daftar Dokter", route: "/doctor/list" },
      { title: "Perjanjian" },
    ])
    this.getUser()
    this.pagination()
  }

}
</script>